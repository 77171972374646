import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { BannerLink } from "@src/appV2/lib/Alert";

export function AccountRestrictionBanner() {
  const enablePAExclusionExperiment = useCbhFlag(CbhFeatureFlag.PA_EXCLUSION_EXPERIMENT, {
    defaultValue: false,
  });

  return (
    <BannerLink to={DeprecatedGlobalAppV1Paths.RATINGS_ATTENDANCE_SCORE} severity="warning">
      {enablePAExclusionExperiment
        ? "Your account is Restricted. You may only book and work shifts at workplaces where you are a Favorite, though you may not receive Priority Access notifications for some facilities."
        : "Your account is restricted. You may only book and work shifts at workplaces where you are a favorite worker."}
    </BannerLink>
  );
}
