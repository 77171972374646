import { isDefined } from "@clipboard-health/util-ts";
import { Box, Chip, CircularProgress, Skeleton, Stack } from "@mui/material";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { useGetAgentFriends } from "@src/appV2/Agents/api/useGetAgentFriends";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { type Shift, ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { format, isAfter, parseISO } from "date-fns";
import { groupBy } from "lodash";
import { z } from "zod";

import { useAgentShifts } from "../api/useAgentShifts";
import { type ShiftSlotsRequest, useGetShiftSlots } from "../api/useGetShiftSlots";
import { useOpenShiftsByDate } from "../api/useOpenShiftByDate";
import { OpenShiftFilters } from "../components/OpenShiftCalendar/OpenShiftFilters";
import { shiftPeriodIcons, shiftPeriodLabels } from "../constants";
import { groupShiftsByDateAndPeriod } from "../utils";
import { generateShiftSlots } from "../utils/generateShiftSlots";
import { groupFriendsByShiftPeriod } from "../utils/groupFriendsByShiftPeriod";
import { ShiftPeriodCard } from "./ShiftPeriodCard";
import { type OnShiftPeriodClickProps } from "./types";

interface DayViewProps {
  date: string;
  onShiftPeriodClick: (props: OnShiftPeriodClickProps) => void;
}

export function OpenShiftDayView(props: DayViewProps) {
  const { date: urlDate, onShiftPeriodClick } = props;
  const parsedDate = z.date().parse(parseISO(urlDate));

  const { data: agent, isSuccess: isAgentSuccess, isLoading: isAgentLoading } = useAgentProfile();
  const { filters, setFilters } = useAgentPreferences();

  const {
    data: openShiftsData,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
  } = useOpenShiftsByDate(
    {
      dateFilter: {
        start: format(parsedDate, "yyyy-MM-dd"),
        end: format(parsedDate, "yyyy-MM-dd"),
      },
      coordinates: agent?.geoLocation?.coordinates ?? [0, 0],
      distance: agent?.preference.distance ?? 150,
      qualification: agent?.preference.qualification ?? "",
      specialities: {
        hasSedationExperience: agent?.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: agent?.specialities?.hasTrayAssemblyExperience ?? false,
      },
    },
    {
      enabled:
        isAgentSuccess &&
        isDefined(agent?.userId) &&
        isDefined(filters?.distance) &&
        isDefined(filters?.license) &&
        agent?.geoLocation?.coordinates.length === 2,
    }
  );

  const {
    data: agentShiftsData,
    isLoading: agentShiftsIsLoading,
    isSuccess: agentShiftsIsSuccess,
  } = useAgentShifts(
    {
      startDate: parsedDate,
      endDate: parsedDate,
      tmz: agent?.tmz ?? "",
      groupByDate: false,
    },
    {
      enabled: isAgentSuccess && isDefined(agent?.userId),
    }
  );

  const shiftSlots: ShiftSlotsRequest["workplaceSlots"] = generateShiftSlots(
    agentShiftsData?.agentShifts ?? [],
    openShiftsData?.openAgentShifts ?? []
  );

  const { data: shiftSlotsData } = useGetShiftSlots(
    {
      workplaceSlots: shiftSlots,
    },
    {
      enabled:
        isDefined(agent?.userId) &&
        openShiftsIsSuccess &&
        agentShiftsIsSuccess &&
        shiftSlots.length > 0,
    }
  );

  const { data: workerFriendsData } = useGetAgentFriends();

  const friendsByShiftPeriod = groupFriendsByShiftPeriod({
    openShifts: openShiftsData?.openAgentShifts ?? [],
    shiftSlots: shiftSlotsData ?? [],
    workerFriends: workerFriendsData ?? [],
  });

  const openShiftsGroupedByShiftPeriod = openShiftsIsSuccess
    ? groupBy(
        openShiftsData?.openAgentShifts.filter((shift) => !shift.filtered),
        (shift: Shift) => {
          return shift.name;
        }
      )
    : { [ShiftPeriod.AM]: [], [ShiftPeriod.PM]: [], [ShiftPeriod.NOC]: [] };

  const agentShiftsGroupedByDateAndShiftPeriod =
    agentShiftsIsSuccess && isAgentSuccess
      ? groupShiftsByDateAndPeriod(agentShiftsData.agentShifts ?? [], agent.tmz)
      : {};

  const agentShiftsCountGroupedByShiftPeriod = agentShiftsGroupedByDateAndShiftPeriod[urlDate];

  useLogEffect(APP_V2_USER_EVENTS.OPEN_SHIFTS_DAY_VIEW_VIEWED);

  return (
    <>
      <Box sx={{ paddingBottom: 2 }} aria-label="Open shift day view filters">
        {isAgentSuccess ? (
          <OpenShiftFilters
            agent={agent}
            disabled={openShiftsIsLoading ?? agentShiftsIsLoading}
            filters={filters ?? { distance: 150, license: "" }}
            setFilters={setFilters}
          />
        ) : null}

        {isAgentLoading ? (
          <Stack direction="row" spacing={2} justifyContent="center" paddingBottom={1}>
            <Chip
              disabled
              color="primary"
              icon={<CircularProgress size={20} />}
              sx={{ width: 100, margin: "auto", paddingLeft: 1 }}
            />
          </Stack>
        ) : null}
      </Box>

      {openShiftsIsLoading ?? agentShiftsIsLoading ? (
        <Stack spacing={2}>
          {shiftPeriodLabels.map((shiftPeriodLabel) => {
            return (
              <Skeleton key={shiftPeriodLabel} variant="rectangular" width="100%" height={105} />
            );
          })}
        </Stack>
      ) : (
        <Stack spacing={3}>
          {shiftPeriodLabels.map((shiftPeriodLabel) => {
            const openShiftsForPeriod = openShiftsGroupedByShiftPeriod[shiftPeriodLabel] ?? [];
            const agentShiftsForPeriod =
              agentShiftsCountGroupedByShiftPeriod?.[shiftPeriodLabel] ?? 0;
            const friendsForShiftPeriod = friendsByShiftPeriod.get(shiftPeriodLabel) ?? [];

            const Icon = shiftPeriodIcons[shiftPeriodLabel];
            const isActive = openShiftsForPeriod.length > 0 || agentShiftsForPeriod > 0;

            const priorityShiftCount = openShiftsForPeriod.filter(
              (shift) =>
                isDefined(shift?.priorityTill) && isAfter(parseISO(shift.priorityTill), new Date())
            ).length;

            return (
              <ShiftPeriodCard
                key={shiftPeriodLabel}
                label={shiftPeriodLabel}
                isActive={isActive}
                Icon={Icon}
                openShifts={openShiftsForPeriod}
                hasBookedShift={agentShiftsForPeriod > 0}
                priorityShiftCount={priorityShiftCount}
                shiftFriends={friendsForShiftPeriod}
                onClick={onShiftPeriodClick}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
}
